.modulation-controls-container{
  position: absolute;
  top: 5rem;
  left:0;
  z-index: 3;

}

.toggle-modulation-menu{
  position: absolute;
  left: 1rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 2;
}

.wave-icon {
  margin: 0;
  transition: 0.5s ease;
}
.wave-icon.expanded {
  opacity: .2;
  transition: 0.7s ease;
}


.modulation-controls {
  display: flex;
  justify-content: space-between;
  margin: 0 0;
  flex-direction: row;
  position: absolute;
  left: 0;
  top: -2rem;
  gap:  0 0.25rem;
  padding: 1rem 4rem;
  visibility: hidden;
  z-index: 0;
}

.modButton {
  width: calc(10vw);
  flex-direction: row;
  border-radius: 1rem;
  cursor: pointer;
  z-index: 0;

}

.mainModButton{
  width: calc(20vw - 20px);
  z-index: 0;

}

.modButton.active {
  background-color: green;
  color: white;
  z-index: 0;

}

.modButton.inactive {
  background-color:hsla(344, 13%, 74%, 0.384);
  color: black;
  z-index: 0;

}

.readout{
  align-self: center;
  width: 4rem;
  color: #33ff33;
  height: 2rem;
  background-color: #64636390;
  padding: 1rem;
  border-radius: 1rem;
  z-index: 0;
  
  }