body{
  margin: 0;
  background-color:hsla(344, 13%, 74%, 0.3);
  font-family: 'Courier New', Courier, monospace;
  text-align:center;
}

main{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 2rem;
  margin: 4rem;
  height: 80vh;
  border-radius: 3rem;
  /* background: linear-gradient(
    to top right, 
    hsla(235, 17%, 74%, 0.1) 0%, 
    hsla(265, 1%, 74%, 0.19) 67%); */
  z-index: 1;
}

.page-content{
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
min-height: 70vh;
padding: 0rem 3rem;
margin: 0 1rem;
border-radius: 3rem;
background:linear-gradient( 110deg,
  hsla(145, 10%, 80%, 0.1) 37%,
  hsla(235, 12%, 60%, 0.084)) 0%;
z-index: 1;
}

.wave-container{
  position: absolute;
  width:101%;
  height: 0%;
  z-index: 1;
}
.wave {
  left: 0;
  z-index: 1;
}


a {
  text-decoration: none;
}

.svglink{
  padding: auto 1rem;
  margin: 3rem 1rem;
}

.svglink img {
  width: 50px; 
  height: 50px;
}

p.text {
  margin: 0rem 1rem 3rem 1rem;
  padding: 1rem 0 2rem 0;
  text-align: left;
}


/* .redbox{
  display: flex;
  justify-content:space-between;
  margin: 1rem 1rem 0rem 1rem;
  background-color: #f44336;
  color: white;
  padding: 1rem 2rem;
  display: inline-block;
  border-radius: 3rem;  
} */

/* .textBox{

  padding: 0rem 12rem;
  margin: 5rem 1rem -1rem 1rem;
  border-radius: 1.5rem;
  background-color: hsl(157, 42%, 58%, 0.2);
} */

.nav{
  list-style: none;
}

.name{
  margin: 0 0 1rem 0;
  padding: 0rem 1rem 1rem 1rem;
  display: flex;
  font-family: 'Courier New', Courier, monospace;
}

